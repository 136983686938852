<template>
    <div style="border: 1px solid #ccc;">
        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
        <Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
            @onCreated="onCreated" />
    </div>
</template>


<script>

import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { notification } from "ant-design-vue";
export default Vue.extend({
    components: { Editor, Toolbar },
    props: {
        content: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            editor: null,
            html: '',
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    uploadImage: {
                        server: '/api/upload/wangeditor',
                        onError(file, err, res) {
                            notification.error({
                                message: `${file.name} 上传出错`,
                                duration: 2,
                            });
                            console.log(`${file.name} 上传出错`, err, res)
                        },
                    },
                }
            },
            mode: 'default', // or 'simple'
        }
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
    },
    mounted() {
        
    },
    watch: {
        'html'(n, o) {
            this.$emit('changeContent', n)
        },
        "content"(n, o) {
            this.html = n
        }
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
})
</script>

<style src="@wangeditor/editor/dist/css/style.css">

</style>
